import React from 'react'
import Category from './Category';
import Poster from '../Poster/Poster';
import Categories from "../categories/Categories";
import { useDispatch, useSelector } from "react-redux";

const SingleCategory = () => {
  const { categories } = useSelector (( state )=> state);

  return <>
    <Poster/>
    <Categories products={categories.list} amount={5}/>
    <Category/>
  </>

};

export default SingleCategory