import React from "react";
import styles from "../../styles/Catalog.module.css";

const OurCatalog = () => {
  return (
    <section className={styles.section}>
      <div className={styles.msg}>
        <h1 className={styles.title}>Наш каталог цветов</h1>
        <p>
          Ваш гид в мир цветочного очарования от Jasmine flower shop 
        </p>
      </div>
    </section>
  );
};

export default OurCatalog;