import React from "react";
import { motion } from "framer-motion";
import { useInView } from "react-intersection-observer";

import styles from "../../styles/Home.module.css";

const ReviewsSection = () => {
  const isMobile = window.innerWidth <= 760;

  const bannerStyle = {
    width: isMobile ? "100vw" : "760px",
    height: "600px",
    overflow: "hidden",
    position: "relative",
  };

  const iframeStyle = {
    width: "100%",
    height: "100%",
    border: "1px solid #FFFFFF",
    borderRadius: "8px",
    boxSizing: "border-box",
  };

  const linkStyle = {
    boxSizing: "border-box",
    textDecoration: "none",
    color: "#b3b3b3",
    fontSize: "10px",
    fontFamily: "YS Text, sans-serif",
    padding: "0 20px",
    position: "absolute",
    bottom: "8px",
    width: "100%",
    textAlign: "center",
    left: "0",
    overflow: "hidden",
    textOverflow: "ellipsis",
    display: "block",
    maxHeight: "14px",
    whiteSpace: "nowrap",
    padding: "0 16px",
    boxSizing: "border-box",
  };

  const [ref, inView] = useInView({
    triggerOnce: true,
    threshold: 0.2,
  });

  const variants = {
    hidden: { opacity: 0, scale: 0.95 }, 
    visible: { 
      opacity: 1, 
      scale: 1, 
      transition: { duration: 0.8, ease: "easeOut" }
    },
  };

  return (
    <section className={styles.rev} ref={ref}>
      <motion.div 
        style={bannerStyle} 
        initial="hidden" 
        animate={inView ? "visible" : "hidden"} 
        variants={variants} 
      >
        <iframe 
          style={iframeStyle} 
          src="https://yandex.ru/maps-reviews-widget/231432842953?comments"
        ></iframe>
        <a 
          href="https://yandex.ru/maps/org/magazin_tsvetov_zhasmin/231432842953/" 
          target="_blank" 
          style={linkStyle}
        >
          Отзывы о Jasmine flower shop в Адлере
        </a>
      </motion.div>
    </section>
  );
};

export default ReviewsSection;