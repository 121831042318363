import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getCategories } from "../../features/categories/categoriesSlice";
import { getProducts } from "../../features/products/productsSlice";
import { check, setCart } from "../../features/user/userSlice";
import AppRoutes from "../Routes/Routes";
import Header from "../Header/Header";
import Footer from "../Footer/Footer";
import UserForm from "../User/UserForm";
import Modal from "../Modal/Modal";

const App = () => {
  const [modalActive, setModalActive] = useState(true)
  const dispatch = useDispatch();
  const userCart = useSelector((state) => state.user.cart);

  useEffect(() => {
    
    const checkToken = async () => {
      try {
        const res = await dispatch(check());
      } catch (error) {
      }
    };
    checkToken();
    dispatch(getCategories());
    dispatch(getProducts());
    const storedCart = localStorage.getItem("cart");
    if (storedCart) {
      const parsedCart = JSON.parse(storedCart);
      dispatch(setCart(parsedCart));
    }
  }, [dispatch]);

  useEffect(() => {
    localStorage.setItem("cart", JSON.stringify(userCart));
  }, [userCart]);

  return (
    <div>
      <Header />
      <UserForm />
      <AppRoutes />
      <Footer />
      <Modal active={modalActive} setActive={setModalActive}/>
    </div>
  );
};

export default App;