import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  addItemToCart,
  removeItemFromCart,
  setCart,
} from "../../features/user/userSlice";
import { sendOrder } from "../../features/order/orderSlice.js";
import styles from "../../styles/Orders.module.css";
import { sumBy } from "../../utils/common";
import { useNavigate } from "react-router-dom";
import InputMask from "react-input-mask";
import { SH_BASE_URL } from "../../utils/constants";
import { motion, AnimatePresence } from "framer-motion";

const Cart = () => {
  const dispatch = useDispatch();
  const { cart, currentUser } = useSelector((state) => state.user);
  const navigate = useNavigate();

  const [userData, setUserData] = useState({
    name: "",
    street: "",
    house: "",
    apartment: "",
    entrance: "",
    phoneNumber: "",
    status: "Поступил",
  });

  const [deliveryType, setDeliveryType] = useState("pickup");

  useEffect(() => {
    const storedCart = localStorage.getItem("cart");
    if (storedCart) {
      const parsedCart = JSON.parse(storedCart);
      dispatch(setCart(parsedCart));
    }
  }, [dispatch]);

  useEffect(() => {
    localStorage.setItem("cart", JSON.stringify(cart));
    if (currentUser) {
      setUserData((prevData) => ({
        ...prevData,
        name: currentUser.name || "",
        phoneNumber: currentUser.phoneNumber || "",
      }));
    }
  }, [currentUser, cart]);

  const changeQuantity = (item, quantity) => {
    dispatch(addItemToCart({ ...item, quantity }));
  };

  const removeItem = (id) => {
    dispatch(removeItemFromCart(id));
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setUserData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleProceedToPayment = async () => {
    if (cart.length === 0) {
      navigate("/");
    } else if (!userData.name || !userData.phoneNumber) {
      openUnfilledPopup();
    } else {
      const orderData = {
        name: userData.name,
        phoneNumber: userData.phoneNumber,
        isDelivery: isDelivery,
        street: userData.street,
        house: userData.house,
        apartment: userData.apartment,
        entrance: userData.entrance,
        status: userData.status,
        deliveryTime: isDelivery ? parseInt(deliveryTime, 10) : null,
        cart: cart,
        totalPrice: sumBy(cart.map(({ quantity, price }) => quantity * price)),
      };

      try {
        await dispatch(sendOrder(orderData));
        openSuccessPopup();
      } catch (error) {
      }
    }
  };
  const [isUnfilledPopupOpen, setIsUnfilledPopupOpen] = useState(false);
  const openUnfilledPopup = () => {
    setIsUnfilledPopupOpen(true);
  };
  const closeUnfilledPopup = () => {
    setIsUnfilledPopupOpen(false);
  };

  const [isSuccessPopupOpen, setIsSuccessPopupOpen] = useState(false);
  const openSuccessPopup = () => {
    setIsSuccessPopupOpen(true);
  };
  const closeSuccessPopup = () => {
    setIsSuccessPopupOpen(false);
  };

  const [isDelivery, setIsDelivery] = useState(false);
  const handleToggleDelivery = () => {
    setIsDelivery(!isDelivery);
  };

  const [deliveryTime, setDeliveryTime] = useState(null);

  const generateDeliveryTimeOptions = () => {
    const startTime = 10;
    const endTime = 22;
    const interval = 2;
    const options = [];

    for (let hour = startTime; hour <= endTime; hour += interval) {
      const formattedHour = hour < 10 ? `0${hour}` : hour;
      options.push(
        <option key={formattedHour} value={formattedHour}>
          {formattedHour}:00 - {formattedHour + interval}:00
        </option>
      );
    }

    return options;
  };
  const handleOverlayClick = (e) => {
    if (
      e.target.classList.contains(styles.overlay) ||
      e.target.classList.contains(styles.unfilledOverlay) ||
      e.target.classList.contains(styles.successOverlay)
    ) {
      closeUnfilledPopup();
      closeSuccessPopup();
    }
  };

  return (
    <div className={styles.orders}>
      <motion.div
        className={styles.userData}
        initial={{ opacity: 0, y: 50 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5 }}
      >
        <h2>Введите ваши данные</h2>
        <label>
          <input
            placeholder="Ваше имя"
            className={styles.inputs}
            type="text"
            name="name"
            value={userData.name}
            onChange={handleInputChange}
          />
        </label>
        <label>
          <InputMask
            className={styles.inputs}
            mask="+7 (999) 999-99-99"
            placeholder="Ваш номер телефона"
            name="phoneNumber"
            value={userData.phoneNumber}
            autoComplete="off"
            maskChar=""
            onChange={handleInputChange}
            required
          />
        </label>

        <label className={styles.checkboxContainer}>
          Доставка:
          <input
            type="checkbox"
            checked={isDelivery}
            onChange={handleToggleDelivery}
            className={styles.checkboxInput}
          />
          <span className={styles.checkboxBackground}></span>
          <span className={styles.checkboxSwitch}></span>
        </label>
        <AnimatePresence>
          {isDelivery && (
            <motion.div
              initial={{ opacity: 0, height: 0 }}
              animate={{ opacity: 1, height: "auto" }}
              exit={{ opacity: 0, height: 0 }}
              transition={{ duration: 0.5 }}
            >
              <div className={styles.deliveryConditions}>
                <p>Условия доставки:</p>
                <p>На доставку мы принимаем заказы с общей суммой от 1500 р.</p>
                <p>
                  Предварительная стоимость доставки по Адлеру центр 300 рублей,
                  Сириус 500 р, Сочи от 800 рублей, Красная поляна от 1000 р.
                </p>
                <p>
                  Итоговую цену уточняйте при заказе, она будет зависеть от
                  конкретного адреса и загруженности дорог.
                </p>
              </div>
              <label>
                <input
                  placeholder="Улица"
                  className={styles.inputs}
                  type="text"
                  name="street"
                  value={userData.street}
                  onChange={handleInputChange}
                />
              </label>
              <label>
                <input
                  placeholder="Номер дома"
                  className={styles.inputs}
                  type="text"
                  name="house"
                  value={userData.house}
                  onChange={handleInputChange}
                />
              </label>
              <label>
                <input
                  placeholder="Номер квартиры"
                  className={styles.inputs}
                  type="text"
                  name="apartment"
                  value={userData.apartment}
                  onChange={handleInputChange}
                />
              </label>
              <label>
                <input
                  placeholder="Номер подъезда"
                  className={styles.inputs}
                  type="text"
                  name="entrance"
                  value={userData.entrance}
                  onChange={handleInputChange}
                />
              </label>
              <label>
                Выберите время доставки:
                <div className="selectWrapper">
                  <select
                    className={`${styles.inputs} ${styles.select}`}
                    name="deliveryTime"
                    value={deliveryTime}
                    onChange={(e) => setDeliveryTime(e.target.value)}
                    required
                  >
                    <option value="" disabled>
                      -- Выберите время --
                    </option>
                    {generateDeliveryTimeOptions()}
                  </select>
                  <div className={`${styles.selectArrow}`}></div>
                </div>
              </label>
            </motion.div>
          )}
        </AnimatePresence>
        <div className={styles.proceedContainer}>
          {cart.length > 0 && (
            <div className={styles.totalPrice}>
              Итого:{" "}
              <span>
                {sumBy(cart.map(({ quantity, price }) => quantity * price))}{" "}
                Руб.
              </span>
            </div>
          )}
          <motion.button
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.95 }}
            onClick={handleProceedToPayment}
          >
            Отправить заявку
          </motion.button>
        </div>
      </motion.div>

      <motion.div
        className={styles.cart}
        initial={{ opacity: 0, x: 50 }}
        animate={{ opacity: 1, x: 0 }}
        transition={{ duration: 0.5 }}
      >
        <h2>Ваша корзина</h2>
        {cart.length ? (
          <div className={styles.list}>
            {cart.map((item) => {
              const { title, image1, price, id, quantity } = item;

              return (
                <motion.div
                  className={styles.item}
                  key={id}
                  initial={{ opacity: 0, y: 20 }}
                  animate={{ opacity: 1, y: 0 }}
                  exit={{ opacity: 0, y: 20 }}
                  transition={{ duration: 0.5 }}
                >
                  <img
                    className={styles.image}
                    src={`${SH_BASE_URL}/${image1}`}
                    alt={`${title} в корзине - Jasmine flower shop`} 
                  />
                  <div className={styles.info}>
                    <h3 className={styles.name}>{title}</h3>
                  </div>

                  <div className={styles.price}>
                    Цена за штуку: {price} Руб.
                  </div>

                  <div className={styles.quantity}>
                    Количество:
                    <div
                      className={styles.minus}
                      onClick={() =>
                        changeQuantity(item, Math.max(1, quantity - 1))
                      }
                    >
                      -
                    </div>
                    <span>{quantity}</span>
                    <div
                      className={styles.plus}
                      onClick={() =>
                        changeQuantity(item, Math.max(1, quantity + 1))
                      }
                    >
                      +
                    </div>
                  </div>

                  <div className={styles.total}>
                    Итого: {price * quantity} Руб.
                  </div>

                  <div
                    className={styles.close}
                    onClick={() => removeItem(item.id)}
                  >
                    &times;
                  </div>
                </motion.div>
              );
            })}
          </div>
        ) : (
          <div className={styles.empty}>Здесь пусто</div>
        )}
        <AnimatePresence>
          {isUnfilledPopupOpen && (
            <motion.div
              className={`${styles.overlay} ${styles.unfilledOverlay}`}
              onClick={handleOverlayClick}
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              transition={{ duration: 0.5 }}
            >
              <motion.div
                className={styles.popupContent}
                initial={{ scale: 0.8 }}
                animate={{ scale: 1 }}
                exit={{ scale: 0.8 }}
                transition={{ duration: 0.5 }}
              >
                <div
                  className={styles.closeButton}
                  onClick={closeUnfilledPopup}
                >
                  &times;
                </div>
                <p>Заполните все поля для оформления заказа!</p>
              </motion.div>
            </motion.div>
          )}
        </AnimatePresence>

        <AnimatePresence>
          {isSuccessPopupOpen && (
            <motion.div
              className={`${styles.overlay} ${styles.successOverlay}`}
              onClick={handleOverlayClick}
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              transition={{ duration: 0.5 }}
            >
              <motion.div
                className={styles.popupContent}
                initial={{ scale: 0.8 }}
                animate={{ scale: 1 }}
                exit={{ scale: 0.8 }}
                transition={{ duration: 0.5 }}
              >
                <div
                  className={styles.closeButton}
                  onClick={closeSuccessPopup}
                >
                  &times;
                </div>
                <p>Заказ успешно оформлен!</p>
              </motion.div>
            </motion.div>
          )}
        </AnimatePresence>

        <div className={styles.actions}>
          <div className={styles.total}>
            Итого:{" "}
            <span>
              {sumBy(cart.map(({ quantity, price }) => quantity * price))} Руб.
            </span>
          </div>
        </div>
      </motion.div>
    </div>
  );
};

export default Cart;
