import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  createProduct,
  deleteProduct,
  updateProduct,
} from "../../features/products/productsSlice";
import {
  createCategory,
  deleteCategory,
} from "../../features/categories/categoriesSlice";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import { useGetProductQuery } from "../../features/api/apiSlice";

import styles from "../../styles/Profile.module.css";

const Profile = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { currentUser } = useSelector(({ user }) => user);
  const { list: products } = useSelector(({ products }) => products);
  const { list: categories } = useSelector(({ categories }) => categories);
  const [showModal, setShowModal] = useState(false);
  const [modalMessage, setModalMessage] = useState("");
  const [imageLabels, setImageLabels] = useState([]);

  const [category, setCategory] = useState({
    name: "",
  });

  const [productValues, setProductValues] = useState({
    id: "",
    title: "",
    price: 0,
    description: "",
    images: [],
    categoryId: null,
  });

  const { data: product, isLoading } = useGetProductQuery({
    id: productValues.id,
  });

  const [values, setValues] = useState({
    name: "",
    role: "",
  });

  useEffect(() => {
    if (!currentUser) return;

    setValues(currentUser);
  }, [currentUser]);

  useEffect(() => {
    if (product) {
      setProductValues({
        id: product.id,
        title: product.title || "",
        price: product.price || 0,
        description: product.description || "",
        images: product.images || [],
        categoryId: product.categoryId || null, 
      });
    }
  }, [product]);

  const handleChange = ({ target: { name, type, value, files } }) => {
    if (name === "id") {
      const selectedProduct = products.find((product) => product.id === value);
      if (selectedProduct) {
        setProductValues({
          id: selectedProduct.id,
          title: selectedProduct.title || "",
          price: selectedProduct.price || 0,
          description: selectedProduct.description || "",
          images: selectedProduct.images || [],
          categoryId: selectedProduct.categoryId || null,
        });
      }
    }
    if (name === "previewImage") {
      const previewImage = files[0];
      const imageValue = previewImage ? previewImage : null;
      setProductValues((prevProductValues) => ({
        ...prevProductValues,
        images: [
          imageValue,
          ...prevProductValues.images.slice(1),
        ], 
      }));
      setImageLabels((prevImageLabels) => [
        "Превью",
        ...prevImageLabels.slice(0, prevImageLabels.length - 1),
      ]);
    } else if (name === "images") {
      const updatedImages = [...productValues.images];
      const selectedFiles = Array.from(files).slice(0, 3);
      for (let i = 0; i < selectedFiles.length; i++) {
        const imageValue = selectedFiles[i] ? selectedFiles[i] : null;
        updatedImages[i + 1] = imageValue;
        setImageLabels((prevImageLabels) => [
          ...prevImageLabels.slice(0, i + 1),
          `Дополнительное изображение ${i + 1}`,
          ...prevImageLabels.slice(i + 1, prevImageLabels.length),
        ]);
      }
      setProductValues((prevProductValues) => ({
        ...prevProductValues,
        images: updatedImages,
      }));
    } else {
      setProductValues((prevProductValues) => ({
        ...prevProductValues,
        [name]: type === "file" ? Array.from(files) : value,
      }));
    }
  };

  const handleCatChange = (e) => {
    const { name, value } = e.target;
    if (name === "name") {
      setCategory((prevCategory) => ({
        ...prevCategory,
        name: value,
      }));
    }
  };

  const handleDeleteCategory = (categoryId) => {
    dispatch(deleteCategory(categoryId));
    openModal("Категория успешно удалена!");
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    dispatch(createProduct(productValues));
    openModal("Товар успешно добавлен!", navigateToProfile);
  };

  const navigateToProfile = () => {
    navigate("/profile");
  };

  const handleDelete = (productId) => {
    dispatch(deleteProduct(productId));
  };

  const handleDeleteSubmit = (e) => {
    e.preventDefault();
    handleDelete(productValues.id);
    openModal("Товар успешно удален!");
  };

  const handleUpdateSubmit = (e) => {
    e.preventDefault();
    dispatch(updateProduct(productValues));
    openModal("Информация о товаре успешно обновлена!");
  };

  const catAdd = (e) => {
    e.preventDefault();
    dispatch(createCategory(category.name));
    openModal("Категория успешно добавлена!");
  };

  useEffect(() => {
    if (values.role !== "ADMIN" && values.role !== "") {
      navigate("/logout");
    }
  }, [navigate, values.role]);

  const renderImagePreviews = () => {
    return (
      <div className={styles.imageContainer}>
        {productValues.images.map((image, index) => (
          <div key={index} className={styles.imageWrapper}>
            <img
              src={URL.createObjectURL(image)}
              alt={`Image Preview ${index}`}
              className={styles.imagePreview}
            />
            <span className={styles.imageLabel}>{imageLabels[index]}</span>
          </div>
        ))}
      </div>
    );
  };

  const openModal = (message) => {
    setModalMessage(message);
    setShowModal(true);
  };

  const closeModal = () => {
    setShowModal(false);
    navigateToProfile();
  };

  return (
    <section className={styles.profile}>
      {values.role !== "ADMIN" ? (
        <span></span>
      ) : (
        <>
          <div className={styles.border}>
            <h2>Добавление товара</h2>
            <form
              className={styles.form}
              onSubmit={handleSubmit}
              encType="multipart/form-data"
            >
              <div className={styles.group}>
                <input
                  type="text"
                  placeholder="Название товара"
                  name="title"
                  value={productValues.title}
                  autoComplete="off"
                  onChange={handleChange}
                  required
                />
              </div>

              <div className={styles.group}>
                <input
                  type="number"
                  placeholder="Цена товара"
                  name="price"
                  value={productValues.price}
                  autoComplete="off"
                  onChange={handleChange}
                  required
                />
              </div>

              <div className={styles.group}>
                <textarea
                  placeholder="Описание товара"
                  name="description"
                  value={productValues.description}
                  autoComplete="off"
                  onChange={handleChange}
                  required
                />
              </div>

              <div className={styles.group}>
                <label>Фото на карточке товара (Превью)</label>
                <input
                  type="file"
                  placeholder="Фото (Превью)"
                  name="previewImage"
                  onChange={handleChange}
                  autoComplete="off"
                  accept="image/*"
                  required
                />
              </div>

              <div className={styles.group}>
                <label>Основные фото товара</label>
                <input
                  type="file"
                  placeholder="Дополнительные фото"
                  name="images"
                  onChange={handleChange}
                  autoComplete="off"
                  multiple={3}
                  accept="image/*"
                  required
                />
                {renderImagePreviews()}
              </div>

              <div className={styles.group}>
                <label>Категория товара:</label>
                <select
                  name="categoryId"
                  value={productValues.categoryId}
                  onChange={handleChange}
                  required
                >
                  <option value="" disabled>
                    Выберите категорию товара
                  </option>
                  {categories.map((cat) => (
                    <option key={cat.id} value={cat.id}>
                      {cat.name}
                    </option>
                  ))}
                </select>
              </div>

              <button type="submit" className={styles.submit}>
                Добавить товар
              </button>
            </form>
          </div>

          <div className={styles.border}>
            <h2>Удаление товара</h2>
            <form className={styles.form} onSubmit={handleDeleteSubmit}>
              <div className={styles.group}>
                <label>Выберите товар для удаления:</label>
                <select
                  name="id"
                  value={productValues.id}
                  onChange={handleChange}
                  required
                >
                  <option value="" disabled>
                    Выберите товар
                  </option>
                  {products.map((product) => (
                    <option key={product.id} value={product.id}>
                      {product.title}
                    </option>
                  ))}
                </select>
              </div>
              <button type="submit" className={styles.submit}>
                Удалить товар
              </button>
            </form>
          </div>

          <div className={styles.border}>
            <h2>Обновление информации о товаре</h2>
            <form
              className={styles.form}
              onSubmit={handleUpdateSubmit}
              encType="multipart/form-data"
            >
              <div className={styles.group}>
                <label>Выберите товар для обновления:</label>
                <select
                  name="id"
                  value={productValues.id}
                  onChange={handleChange}
                  required
                >
                  <option value="" disabled>
                    Выберите товар
                  </option>
                  {products.map((product) => (
                    <option key={product.id} value={product.id}>
                      {product.title}
                    </option>
                  ))}
                </select>
              </div>
              <div className={styles.group}>
                <input
                  type="text"
                  placeholder="Новое название товара"
                  name="title"
                  value={productValues.title}
                  autoComplete="off"
                  onChange={handleChange}
                  required
                />
              </div>
              <div className={styles.group}>
                <input
                  type="number"
                  placeholder="Новая цена товара"
                  name="price"
                  value={productValues.price}
                  autoComplete="off"
                  onChange={handleChange}
                  required
                />
              </div>
              <div className={styles.group}>
                <textarea
                  placeholder="Новое описание товара"
                  name="description"
                  value={productValues.description}
                  autoComplete="off"
                  onChange={handleChange}
                  required
                />
              </div>
              <div className={styles.group}>
                <label>Фото на карточке товара (Превью)</label>
                <input
                  type="file"
                  placeholder="Фото (Превью)"
                  name="previewImage"
                  onChange={handleChange}
                  autoComplete="off"
                  accept="image/*"
                />
              </div>

              <div className={styles.group}>
                <label>Основные фото товара</label>
                <input
                  type="file"
                  placeholder="Дополнительные фото"
                  name="images"
                  onChange={handleChange}
                  autoComplete="off"
                  multiple={3}
                  accept="image/*"
                />
                {renderImagePreviews()}
              </div>

              <div className={styles.group}>
                <label>Категория товара:</label>
                <select
                  name="categoryId"
                  value={productValues.categoryId}
                  onChange={handleChange}
                  required
                >
                  <option value="" disabled>
                    Выберите категорию товара
                  </option>
                  {categories.map((cat) => (
                    <option key={cat.id} value={cat.id}>
                      {cat.name}
                    </option>
                  ))}
                </select>
              </div>

              <button type="submit" className={styles.submit}>
                Обновить товар
              </button>
            </form>
          </div>

          <div className={styles.border}>
            <h2>Добавление категории</h2>
            <form
              className={styles.form}
              onSubmit={catAdd}
            >
              <div className={styles.group}>
                <input
                  type="text"
                  placeholder="Название категории"
                  name="name"
                  value={category.name}
                  autoComplete="off"
                  onChange={handleCatChange}
                  required
                />
              </div>
              <button type="submit" className={styles.submit}>
                Добавить категорию
              </button>
            </form>
          </div>
          <div className={styles.border}>
            <h2>Удаление категории</h2>
            <form
              className={styles.form}
              onSubmit={(e) => {
                e.preventDefault();
                const categoryIdToDelete = e.target.categoryId.value;
                handleDeleteCategory(categoryIdToDelete);
              }}
            >
              <div className={styles.group}>
                <label>Выберите категорию для удаления:</label>
                <select name="categoryId" required>
                  {" "}
                  <option value="" disabled>
                    Выберите категорию
                  </option>
                  {categories.map((category) => (
                    <option key={category.id} value={category.id}>
                      {category.name}
                    </option>
                  ))}
                </select>
              </div>
              <button type="submit" className={styles.submit}>
                Удалить категорию
              </button>
            </form>
          </div>

          <div className={styles.border}>
            <h2>Выход из аккаунта</h2>
            <Link to="/logout">
              <button className={styles.logoutButton}>Выйти из аккаунта</button>
            </Link>
          </div>
          {showModal && (
            <div className={styles.overlay} onClick={closeModal}>
              <div className={styles.popupContent}>
                <div className={styles.closeButton} onClick={closeModal}>
                  ×
                </div>
                <p>{modalMessage}</p>
              </div>
            </div>
          )}
        </>
      )}
    </section>
  );
};

export default Profile;
