import React from "react";
import { Route, Routes } from "react-router-dom";
import Home from "../Home/Home";
import {ROUTES} from "../../utils/routes"
import SingleProduct from "../Products/SingleProduct";
import Profile from "../Profile/Profile";
import SingleCategory from "../categories/SingleCategory";
import Cart from "../Cart/Cart";
import Logout from "../Logout/LogoutPage"
import OrdersList from "../OrdersList/OrdersList"

const AppRoutes = ()  =>  (
        <Routes>
            <Route index element={<Home />}/>
            <Route path={ROUTES.PRODUCT} element={<SingleProduct/>}/>
            <Route path={ROUTES.PROFILE} element={<Profile/>}/>
            <Route path={ROUTES.CATEGORY} element={<SingleCategory/>}/>
            <Route path={ROUTES.CART} element={<Cart/>}/>
            <Route path={ROUTES.LOGOUT} element={<Logout/>}/>
            <Route path={ROUTES.ORDERLIST} element={<OrdersList/>}/>
        </Routes>
    );

export default AppRoutes;