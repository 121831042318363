import React from "react";
import { Link } from "react-router-dom";
import styles from "../../styles/Categories.module.css";

const generateSlug = (text) => {
  const transliterated = text
    .toLowerCase()
    .replace(/ /g, "-")
    .replace(/а/g, 'a')
    .replace(/б/g, 'b')
    .replace(/в/g, 'v')
    .replace(/г/g, 'g')
    .replace(/д/g, 'd')
    .replace(/е/g, 'e')
    .replace(/ё/g, 'yo')
    .replace(/ж/g, 'zh')
    .replace(/з/g, 'z')
    .replace(/и/g, 'i')
    .replace(/й/g, 'y')
    .replace(/к/g, 'k')
    .replace(/л/g, 'l')
    .replace(/м/g, 'm')
    .replace(/н/g, 'n')
    .replace(/о/g, 'o')
    .replace(/п/g, 'p')
    .replace(/р/g, 'r')
    .replace(/с/g, 's')
    .replace(/т/g, 't')
    .replace(/у/g, 'u')
    .replace(/ф/g, 'f')
    .replace(/х/g, 'kh')
    .replace(/ц/g, 'ts')
    .replace(/ч/g, 'ch')
    .replace(/ш/g, 'sh')
    .replace(/щ/g, 'sch')
    .replace(/ъ/g, '')
    .replace(/ы/g, 'y')
    .replace(/ь/g, '')
    .replace(/э/g, 'e')
    .replace(/ю/g, 'yu')
    .replace(/я/g, 'ya');

  return transliterated.replace(/[^a-z0-9-]/g, "");
};

const Categories = ({ title, products = [], amount }) => {
  const list = products.filter((_, i) => i < 40);

  return (
    <div className={styles.cat}>
      <h2>{title}</h2>
      <div className={styles.categoryList}>
        {list.map(({ id, name }) => (
          <Link
            key={id}
            to={`/categories/${generateSlug(name)}`}
            className={styles.categoryItem}
            onClick={(e) => {
              e.preventDefault();
              const targetId = `category-${id}`;
              const targetElement = document.getElementById(targetId);
              if (targetElement) {
                targetElement.scrollIntoView({ behavior: "smooth" });
              }
            }}
          >
            {name}
          </Link>
        ))}

        <Link
          to="/drugie-tovary" 
          className={styles.categoryItem}
          onClick={(e) => {
            e.preventDefault();
            const targetElement = document.getElementById("drugie-tovary"); 
            if (targetElement) {
              targetElement.scrollIntoView({ behavior: "smooth" });
            }
          }}
        >
          Другие товары
        </Link>
      </div>
    </div>
  );
};

export default Categories;