import React, { useEffect, useState } from 'react'; 
import { motion } from 'framer-motion'; 
import styles from '../../styles/WhatsappButton.module.css';

const WhatsappButton = () => {
  const whatsappNumber = '+79000018783'; 
  const whatsappLink = `https://wa.me/${whatsappNumber}`;
  const [isVisible, setIsVisible] = useState(false); 

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsVisible(true);
    }, 1500);

    return () => clearTimeout(timer);
  }, []);

  const shakeVariants = {
    shake: {
      x: [0, -5, 5, -5, 5, 0],
      transition: {
        duration: 0.5,
        ease: "easeInOut",
        times: [0, 0.2, 0.4, 0.6, 0.8, 1],
        repeat: Infinity,
        repeatDelay: 5,
      },
    },
    visible: {
      opacity: 1, 
      scale: 1, 
      transition: { duration: 0.5, ease: "easeOut", delay: 0.5 }, 
    }
  };

  return (
    <motion.a 
      href={whatsappLink} 
      target="_blank"
      rel="noopener noreferrer"
      className={styles.whatsappButton}
      initial={{ opacity: 0, scale: 0.8 }}
      variants={shakeVariants} 
      animate={isVisible ? { ...shakeVariants.visible, ...shakeVariants.shake } : { opacity: 0, scale: 0.8 }}
      transition={{ duration: 0.5, ease: "easeOut", delay: 0.5 }}
    >
      <i className="fab fa-whatsapp"></i>
    </motion.a>
  );
};

export default WhatsappButton;