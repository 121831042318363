import React from "react";
import "../../styles/Modal.module.css";

const Modal = ({active, setActive}) => {
  return (
  <div className="modal">
    <div className="modal__vontent"></div>
  </div>
  ); 
};

export default Modal;
