import React from "react";
import { motion } from "framer-motion";
import { useInView } from "react-intersection-observer";
import { Carousel } from 'react-responsive-carousel';
import "react-responsive-carousel/lib/styles/carousel.min.css";

import styles from "../../styles/About.module.css";

import gal1Image from "../../images/gal1.jpg";
import gal2Image from "../../images/gal2.jpg";
import gal3Image from "../../images/gal3.jpg";

const About = () => {
  const [ref, inView] = useInView({
    triggerOnce: true,
    threshold: 0.2,
  });

  const variants = {
    hidden: { opacity: 0, y: 50 },
    visible: { opacity: 1, y: 0, transition: { duration: 0.8, ease: "easeOut" } },
  };

  return (
    <section className={styles.about} ref={ref}>
      <motion.h2 
        className={styles.tit} 
        initial="hidden" 
        animate={inView ? "visible" : "hidden"}
        variants={variants}
      >
        Почему выбирают нас
      </motion.h2>

      <motion.div 
        className={styles.blocks} 
        initial="hidden" 
        animate={inView ? "visible" : "hidden"}
        variants={variants}
      >
        <div className={styles.opisanie}>
          <ul>
            <li><span className={styles.icon}>🌸</span>  Мы тщательно собираем уникальные букеты и композиции, обсуждая каждый детальный нюанс. На этапе готовности готовы выслать вам фото и видео для окончательного согласования.</li>
            <li><span className={styles.icon}>🚚</span> Положитесь на нас в каждом аспекте вашего заказа. Мы не только заботимся о стиле букета, но и активно взаимодействуем с получателем, подтверждаем адрес и гарантируем доставку в удобное для вас время.</li>
            <li><span className={styles.icon}>🏆</span> С гордостью можем сообщить, что мы работаем в этой сфере с 2015 года и успешно доставили более 8000 букетов. Наши флористы обладают высоким профессионализмом и точно знают, что предложить вам, чтобы сделать ваш заказ особенным и запоминающимся.</li>
          </ul>
        </div>
        <div className={styles.imaga}>
          <Carousel 
            showThumbs={false} 
            autoPlay={true} 
            infiniteLoop={true} 
            interval={3000} 
            transitionTime={800}
          >
            <div>
              <img 
                src={gal1Image} 
                alt="Авторский букет из разных цветов от Jasmine flower shop" 
                className={styles.carouselImage} 
              />
            </div>
            <div>
              <img 
                src={gal2Image} 
                alt="Корзина роз с доставкой по Адлеру от Jasmine"  
                className={styles.carouselImage}
              />
            </div>
            <div>
              <img 
                src={gal3Image} 
                alt="Свежий букет красных роз от Jasmine flower shop" 
                className={styles.carouselImage}
              />
            </div>
          </Carousel>
        </div>
      </motion.div>
    </section>
  );
};

export default About;