import React from "react";
import styles from "../../styles/BannerTitle.module.css";

const BannerTitle = ({ children }) => {
  return (
    <div className={styles.bannerTitle}>
      <h2>{children}</h2>
    </div>
  );
};

export default BannerTitle;