import React from "react";
import { Link } from "react-router-dom";

import styles from "../../styles/Footer.module.css";
import { ROUTES } from "../../utils/routes";

import LOGO from "../../images/logo.svg";

const Footer = () => (
  <footer className={styles.footer}> 
    <div className={styles.logo}>
      <Link to={ROUTES.HOME}>
        <img src={LOGO} alt="Цветочный магазин Jasmine в Адлере" /> 
      </Link>
    </div>

    <div className={styles.info}> 
      <address className={styles.address}>
        г. Сочи, Адлерский район, <br /> 
        ул. Гастелло, 43к1
      </address>
    </div>
  </footer>
);

export default Footer;