import { createApi, fetchBaseQuery} from "@reduxjs/toolkit/query/react"
import { BASE_URL } from "../../utils/constants"
import { buildUrl } from "../../utils/common";

export const apiSlice = createApi({
  reducerPath: 'api',
  baseQuery: fetchBaseQuery({ baseUrl: BASE_URL }),
  tagTypes: ['Product', 'Category'],
  endpoints: (builder) => ({
    getProduct: builder.query({
      query: ({ id }) => `/products/${id}`,
      providesTags: (result, error, { id }) => [{ type: 'Product', id }],
    }),
    getProducts: builder.query({
      query: (params) => buildUrl('/products', params),
      providesTags: ['Products'],
    }),
    getCategoryById: builder.query({
      query: ({ id }) => `/categories/${id}`,
      providesTags: (result, error, { id }) => [{ type: 'Category', id }],
    }),
    getProductsByCategory: builder.query({
      query: (params) => buildUrl(`/categories/${params.categoryId}/products`, params),
      providesTags: (result, error, { categoryId }) => [{ type: 'Product', categoryId }],
    }),
  }),
});

export const {
  useGetProductQuery,
  useGetProductsQuery,
  useGetCategoryByIdQuery,
  useGetProductsByCategoryQuery,
} = apiSlice;