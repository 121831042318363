import React from 'react';
import styles from '../../styles/Banner.module.css';
import imgBanner from '../../images/ImgBanner.jpg';

const Poster = () => {
  return (
    <section className={styles.header}>
      <img className={styles.headerImage} src={imgBanner} alt="Герберы от Jasmine flower shop в Адлере" />
      <div className={styles.title}>
        <h1 className={styles.titleText}>Jasmine flower<br/>shop</h1>
      </div>
      <span className={styles.visuallyHidden}> 
        Герберы от Jasmine flower shop в Адлере
      </span>
    </section>
  );
};

export default Poster;