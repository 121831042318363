import React from "react";
import { useNavigate } from "react-router-dom";

import styles from "../../styles/LogoutPage.module.css"; // Assuming you have this CSS file

const LogoutPage = () => {
  const navigate = useNavigate();
  const handleConfirm = () => {
    localStorage.removeItem("token");
    localStorage.removeItem("cart");
    navigate("/", { replace: true });
    window.location.reload();
  };

  const handleCancel = () => {
    navigate("/", { replace: true });
  };

  return (
    <div className={styles.logoutPage}>
      <p>Вы уверены, что хотите выйти из аккаунта?</p>
      <div className={styles.buttons}>
        <button onClick={handleCancel}>Отмена</button>
        <button onClick={handleConfirm}>Подтвердить</button>
      </div>
    </div>
  );
};

export default LogoutPage;
