import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";

import { BASE_URL } from "../../utils/constants";

export const getAllOrders = createAsyncThunk("order/getAllOrders", async (_, thunkAPI) => {
  try {
    const token = localStorage.getItem("token");
    const res = await axios.get(`${BASE_URL}/orders`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return res.data;
  } catch (err) {
    return thunkAPI.rejectWithValue(err);
  }
});

export const changeOrderStatus = createAsyncThunk("order/changeOrderStatus", async (statusData, thunkAPI) => {
  try {
    const token = localStorage.getItem("token");
    const res = await axios.put(`${BASE_URL}/orders/${statusData.orderId}/status`, { status: statusData.status }, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return res.data;
  } catch (err) {
    return thunkAPI.rejectWithValue(err);
  }
});

export const deleteOrder = createAsyncThunk("order/deleteOrder", async (orderId, thunkAPI) => {
  try {
    const token = localStorage.getItem("token");
    const res = await axios.delete(`${BASE_URL}/orders/delete/${orderId}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return res.data;
  } catch (err) {
    return thunkAPI.rejectWithValue(err);
  }
});

export const sendOrder = createAsyncThunk("order/sendOrder", async (orderData, thunkAPI) => {
  try {
    const res = await axios.post(`${BASE_URL}/orders/create`, orderData);
    return res.data;
  } catch (err) {
    return thunkAPI.rejectWithValue(err);
  }
});

const orderSlice = createSlice({
  name: "order",
  initialState: {
    isLoading: false,
    success: false,
    error: null,
    cart: [],
    orders: [],
  },
  extraReducers: (builder) => {
    builder.addCase(getAllOrders.pending, (state) => {
      state.isLoading = true;
      state.success = false;
      state.error = null;
    });
    builder.addCase(getAllOrders.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.success = true;
      state.error = null;
      state.orders = payload;
    });
    builder.addCase(getAllOrders.rejected, (state, { payload }) => {
      state.isLoading = false;
      state.success = false;
      state.error = payload;
    });

    builder.addCase(sendOrder.pending, (state) => {
      state.isLoading = true;
      state.success = false;
      state.error = null;
    });
    builder.addCase(sendOrder.fulfilled, (state) => {
      state.isLoading = false;
      state.success = true;
      state.error = null;
    });
    builder.addCase(sendOrder.rejected, (state, { payload }) => {
      state.isLoading = false;
      state.success = false;
      state.error = payload;
    });
  },
});

export const { setCart } = orderSlice.actions;
export default orderSlice.reducer;