import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  getAllOrders,
  changeOrderStatus,
  deleteOrder,
} from "../../features/order/orderSlice";
import styles from "../../styles/OrdersList.module.css";

import DELETE from "../../images/delete.svg";
import FINISH from "../../images/finish.svg";
import OPEN from "../../images/open.svg";

const OrdersList = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { currentUser } = useSelector((state) => state.user);
  const [localOrders, setLocalOrders] = useState([]);
  const [componentLoaded, setComponentLoaded] = useState(false);
  const { isLoading, error } = useSelector((state) => state.order) || {};

  useEffect(() => {
    if (!currentUser || currentUser.role !== "ADMIN") {
      navigate("/");
    } else {
      dispatch(getAllOrders())
        .then((response) => {
          setLocalOrders(response.payload);
          setComponentLoaded(true);
        })
        .catch((err) => {
        });
    }
  }, [currentUser, navigate, dispatch]);

  const handleStatusChange = (orderId, newStatus) => {
    dispatch(changeOrderStatus({ orderId, status: newStatus }));
  };
  const DelOrder = (orderId) => {
    dispatch(deleteOrder(orderId));
  };

  if (!componentLoaded) {
    return <p>Loading...</p>;
  }

  return (
    <div className={styles.ordersList}>
      <h2>Список заказов</h2>
      {error ? (
        <p>Error: {error.message}</p>
      ) : !localOrders ? (
        <p>No orders available</p>
      ) : (
        <table className={styles.table}>
          <thead>
            <tr>
              <th>ID заказа</th>
              <th>Клиент</th>
              <th>Номер телефона</th>
              <th>Доставка</th>
              <th>Товары</th>
              <th>Статус</th>
              <th>Действия</th>
            </tr>
          </thead>
          <tbody>
            {Array.isArray(localOrders) && localOrders.length > 0 ? (
              localOrders.map((order) => (
                <tr key={order.id} className={getStatusClass(order.status)}>
                  <td>{order.id}</td>
                  <td>{order.name}</td>
                  <td>{order.phoneNumber}</td>
                  <td>
                    {order.isDelivery ? (
                      <div>
                        Адрес: {order.street} {order.house}
                        <br />
                        Квартира: {order.apartment}
                        <br />
                        Подъезд: {order.entrance}
                        <br />
                        {formatDeliveryTime(order.deliveryTime)}
                      </div>
                    ) : (
                      "Отсутствует"
                    )}
                  </td>
                  <td>
                    {order.cart && order.cart.length > 0 ? (
                      <ul>
                        {order.cart.map((item) => (
                          <li key={item.productId}>
                            {item.title} - Количество: {item.quantity}
                          </li>
                        ))}
                      </ul>
                    ) : (
                      "Нет товаров в заказе"
                    )}
                  </td>
                  <td>{order.status}</td>
                  <td>
                    <button
                      className={styles.butnik}
                      onClick={() => DelOrder(order.id)}
                    >
                      <img
                        src={DELETE}
                        alt="Delete"
                        className={styles.imgbut}
                      />
                    </button>
                    <button
                      className={styles.butnik}
                      onClick={() => handleStatusChange(order.id, "Открыт")}
                    >
                      <img src={OPEN} alt="Open" className={styles.imgbut} />
                    </button>
                    <button
                      className={styles.butnik}
                      onClick={() => handleStatusChange(order.id, "Выполнен")}
                    >
                      <img
                        src={FINISH}
                        alt="Finish"
                        className={styles.imgbut}
                      />
                    </button>
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan="7">No orders available</td>
              </tr>
            )}
          </tbody>
        </table>
      )}
    </div>
  );
};

const formatDeliveryTime = (deliveryTime) => {
  const startTime = parseInt(deliveryTime, 10);
  const endTime = startTime + 2;
  const formattedTime = `Предпочитаемое время доставки: ${startTime}:00-${endTime}:00`;
  return formattedTime;
};

const getStatusClass = (status) => {
  switch (status) {
    case "Поступил":
      return styles["status-new"];
    case "Открыт":
      return styles["status-processing"];
    case "Выполнен":
      return styles["status-completed"];
    default:
      return "";
  }
};

export default OrdersList;