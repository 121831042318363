import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";

import { BASE_URL } from "../../utils/constants";

export const deleteProduct = createAsyncThunk(
  "products/deleteProduct",
  async (productId, thunkAPI) => {
    try {
      const token = localStorage.getItem("token");
      const res = await axios.delete(`${BASE_URL}/products/delete/${productId}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      return res.data;
    } catch (err) {
      return thunkAPI.rejectWithValue(err);
    }
  }
);

export const createProduct = createAsyncThunk(
  "products/createProduct",
  async (productData, thunkAPI) => {
    try {
      const token = localStorage.getItem("token");
      const formData = new FormData();
      for (const key in productData) {
        if (key === "images") {
          for (const image of productData[key]) {
            formData.append("images", image);
          }
        } else {
          formData.append(key, productData[key]);
        }
      }

      const res = await axios.post(`${BASE_URL}/products/create`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${token}`,
        },
      });
      return res.data;
    } catch (err) {
      return thunkAPI.rejectWithValue(err);
    }
  }
);

export const updateProduct = createAsyncThunk(
  "products/updateProduct",
  async (productData, thunkAPI) => {
    try {
      const token = localStorage.getItem("token");
      const formData = new FormData();
      for (const key in productData) {
        if (key === "images") {
          for (const image of productData[key]) {
            formData.append("images", image);
          }
        } else {
          formData.append(key, productData[key]);
        }
      }

      const res = await axios.put(
        `${BASE_URL}/products/update/${productData.id}`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      return res.data;
    } catch (err) {
      return thunkAPI.rejectWithValue(err);
    }
  }
);

export const getProducts = createAsyncThunk(
  "products/getProducts",
  async (_, thunkAPI) => {
    try {
      const res = await axios(`${BASE_URL}/products`);
      return res.data;
    } catch (err) {
      return thunkAPI.rejectWithValue(err);
    }
  }
);

const productsSlice = createSlice({
  name: "products",
  initialState: {
    list: [],
    filtered: [],
    isLoading: false,
  },
  reducers: {
    filterByPrice: (state, { payload }) => {
      state.filtered = state.list.filter(({ price }) => price < payload);
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getProducts.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getProducts.fulfilled, (state, { payload }) => {
      state.list = payload;
      state.isLoading = false;
    });
    builder.addCase(getProducts.rejected, (state) => {
      state.isLoading = false;
    });

    builder.addCase(createProduct.fulfilled, (state, { payload }) => {
      state.list.push(payload);
    });
    builder.addCase(deleteProduct.fulfilled, (state, { payload }) => {
      state.list = state.list.filter(product => product.id !== payload.id);
    });
    builder.addCase(updateProduct.fulfilled, (state, { payload }) => {
      state.list = state.list.map(product => (product.id === payload.id ? payload : product));
    });
  },
});

export const { filterByPrice } = productsSlice.actions;

export default productsSlice.reducer;