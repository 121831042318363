import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { motion, AnimatePresence } from "framer-motion";
import { useInView } from "react-intersection-observer";

import { ROUTES } from "../../utils/routes";
import styles from "../../styles/Product.module.css";
import { addItemToCart } from "../../features/user/userSlice";
import { sendOrder } from "../../features/order/orderSlice.js";
import InputMask from "react-input-mask";
import { SH_BASE_URL } from "../../utils/constants";
import {
  deleteProduct,
  updateProduct,
} from "../../features/products/productsSlice";
import { getCategories } from "../../features/categories/categoriesSlice";
const generateSlug = (text) => {
  const transliterated = text
    .toLowerCase()
    .replace(/ /g, "-")
    .replace(/а/g, 'a')
    .replace(/б/g, 'b')
    .replace(/в/g, 'v')
    .replace(/г/g, 'g')
    .replace(/д/g, 'd')
    .replace(/е/g, 'e')
    .replace(/ё/g, 'yo')
    .replace(/ж/g, 'zh')
    .replace(/з/g, 'z')
    .replace(/и/g, 'i')
    .replace(/й/g, 'y')
    .replace(/к/g, 'k')
    .replace(/л/g, 'l')
    .replace(/м/g, 'm')
    .replace(/н/g, 'n')
    .replace(/о/g, 'o')
    .replace(/п/g, 'p')
    .replace(/р/g, 'r')
    .replace(/с/g, 's')
    .replace(/т/g, 't')
    .replace(/у/g, 'u')
    .replace(/ф/g, 'f')
    .replace(/х/g, 'kh')
    .replace(/ц/g, 'ts')
    .replace(/ч/g, 'ch')
    .replace(/ш/g, 'sh')
    .replace(/щ/g, 'sch')
    .replace(/ъ/g, '')
    .replace(/ы/g, 'y')
    .replace(/ь/g, '')
    .replace(/э/g, 'e')
    .replace(/ю/g, 'yu')
    .replace(/я/g, 'ya');

  return transliterated.replace(/[^a-z0-9-]/g, "");
};


const Product = (item) => {
  const {
    id,
    title,
    price,
    image1,
    image2,
    image3,
    image4,
    description,
    categoryId,
  } = item;
  const images = [image1, image2, image3, image4];
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const cart = useSelector((state) => state.user.cart);
  const { currentUser } = useSelector(({ user }) => user);
  const { list: categories } = useSelector(({ categories }) => categories);

  const [currentImage, setCurrentImage] = useState(images[0]);
  const [isAddToCartPopupOpen, setIsAddToCartPopupOpen] = useState(false);
  const [isOrderPopupOpen, setIsOrderPopupOpen] = useState(false);
  const [userData, setUserData] = useState({
    name: "Заказ по телефону",
    phoneNumber: "",
    status: "Поступил",
    isDelivery: false,
    cart: [],
  });
  const [showModal, setShowModal] = useState(false);
  const [modalMessage, setModalMessage] = useState("");
  const [isEditing, setIsEditing] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);
    dispatch(getCategories());
    const generatedSlug = `${generateSlug(title)}`;
    window.history.pushState({}, '', `/products/${generatedSlug}`); 
  }, [dispatch, title, id]);

  const addToCart = () => {
    dispatch(addItemToCart(item));
    openAddToCartPopup();
  };

  const handleImageClick = (image) => {
    setCurrentImage(image);
  };

  const openAddToCartPopup = () => {
    setIsAddToCartPopupOpen(true);
    setTimeout(() => {
      setIsAddToCartPopupOpen(false);
    }, 3000);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setUserData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const openOrderPopup = () => {
    setIsOrderPopupOpen(true);
  };

  const closePopup = () => {
    setIsAddToCartPopupOpen(false);
    setIsOrderPopupOpen(false);
  };

  const handleOverlayClick = (e) => {
    if (e.target.classList.contains(styles.overlay)) {
      closePopup();
    }
  };

  const handleProceedToPayment = async () => {
    try {
      const orderData = {
        name: userData.name,
        phoneNumber: userData.phoneNumber,
        isDelivery: userData.isDelivery,
        cart: userData.cart,
      };

      await dispatch(sendOrder(orderData));
    } catch (error) {
      const serializableError = {
        message: error.message,
        name: error.name,
      };

      await dispatch(sendOrder.rejected(serializableError));
    }
  };

  const handleDeleteProduct = () => {
    dispatch(deleteProduct(id));
    window.location.reload();
  };

  const handleSaveProduct = () => {
    const updatedProduct = {
      id,
      title: editedProduct.title,
      price: parseFloat(editedProduct.price),
      description: editedProduct.description,
      images: editedProduct.images,
      categoryId: editedProduct.categoryId,
    };

    dispatch(updateProduct(updatedProduct));
    setIsEditing(false);
    openModal("Информация о товаре успешно обновлена!");
    window.location.reload();
  };

  const [editedProduct, setEditedProduct] = useState({
    title,
    price,
    description,
    images,
    categoryId,
  });

  const handleEditChange = (e) => {
    const { name, value, files } = e.target;
    setEditedProduct((prevProduct) => ({
      ...prevProduct,
      [name]: name === "images" ? Array.from(files) : value,
    }));
  };

  const [ref, inView] = useInView({
    triggerOnce: true,
    threshold: 0.2,
  });

  const variants = {
    hidden: { opacity: 0, x: -50 },
    visible: { opacity: 1, x: 0, transition: { duration: 0.8, ease: "easeOut" } },
  };

  const imageVariants = {
    hidden: { opacity: 0, scale: 0.9 },
    visible: { opacity: 1, scale: 1 },
    exit: { opacity: 0, scale: 1.1 },
  };

  const smallImageVariants = {
    hidden: { opacity: 0, scale: 0.8 },
    visible: { opacity: 1, scale: 1 },
    hover: { scale: 1.1 },
  };

  const openModal = (message, callback = () => {}) => {
    setModalMessage(message);
    setShowModal(true);
    callback();
  };
  const closeModal = () => {
    setShowModal(false);
  };

  return (
    <motion.section
      className={styles.product}
      ref={ref}
      initial="hidden"
      animate={inView ? "visible" : "hidden"}
      variants={variants}
    >
      <div className={styles.images}>
        <AnimatePresence mode="wait">
          <motion.div
            key={currentImage}
            className={`${styles.current}`}
            style={{ backgroundImage: `url(${SH_BASE_URL}/${currentImage})` }}
            alt={`${title} - Jasmine flower shop`}
            initial="hidden"
            animate="visible"
            exit="exit"
            variants={imageVariants}
            transition={{ duration: 0.3 }}
          />
        </AnimatePresence>
        <div className={styles.smallImages}>
          {images.map((image, index) =>
            image && (
              <motion.div
                key={image}
                className={`${styles.small}`}
                style={{ backgroundImage: `url(${SH_BASE_URL}/${image})` }}
                onClick={() => handleImageClick(image)}
                initial="hidden"
                animate="visible"
                whileHover="hover"
                variants={smallImageVariants}
                transition={{ duration: 0.1 }}
                alt={`${title}, фото ${index + 1} - Jasmine flower shop`}
              />
            )
          )}
        </div>
      </div>
      <div className={styles.info}>
        {isEditing ? (
          <form className={styles.form} onSubmit={(e) => e.preventDefault()}>
            <div className={styles.group}>
              <input
                type="text"
                placeholder="Название товара"
                name="title"
                value={editedProduct.title}
                autoComplete="off"
                onChange={handleEditChange}
                required
              />
            </div>
            <div className={styles.group}>
              <input
                type="number"
                placeholder="Цена товара"
                name="price"
                value={editedProduct.price}
                autoComplete="off"
                onChange={handleEditChange}
                required
              />
            </div>
            <div className={styles.group}>
              <textarea
                placeholder="Описание товара"
                name="description"
                value={editedProduct.description}
                autoComplete="off"
                onChange={handleEditChange}
                required
              />
            </div>
            <div className={styles.group}>
              <label htmlFor="previewImage">
                Фото на карточке товара (Превью)
              </label>
              <input
                type="file"
                id="previewImage"
                name="previewImage"
                onChange={handleEditChange}
                accept="image/*"
              />
            </div>
            <div className={styles.group}>
              <label htmlFor="images">Основные фото товара</label>
              <input
                type="file"
                id="images"
                name="images"
                onChange={handleEditChange}
                multiple
                accept="image/*"
              />
            </div>
            <div className={styles.group}>
              <label>Категория товара:</label>
              <select
                name="categoryId"
                value={editedProduct.categoryId}
                onChange={handleEditChange}
                required
              >
                <option value="" disabled>
                  Выберите категорию товара
                </option>
                {categories.map((cat) => (
                  <option key={cat.id} value={cat.id}>
                    {cat.name}
                  </option>
                ))}
              </select>
            </div>
          </form>
        ) : (
          <>
            <h1 className={styles.title}>{title}</h1>
            <p className={styles.description}>{description}</p>
            <div className={styles.price}>{price} Руб.</div>
          </>
        )}
        {currentUser && currentUser.role === "ADMIN" && (
          <div className={styles.adminActions}>
            {isEditing ? (
              <>
                <button onClick={handleSaveProduct} className={styles.save}>
                  Сохранить
                </button>
                <button
                  onClick={() => setIsEditing(false)}
                  className={styles.cancel}
                >
                  Отмена
                </button>
              </>
            ) : (
              <>
                <button
                  onClick={handleDeleteProduct}
                  className={styles.delete}
                >
                  Удалить
                </button>
                <button
                  onClick={() => setIsEditing(true)}
                  className={styles.edit}
                >
                  Изменить
                </button>
              </>
            )}
          </div>
        )}

        <div className={styles.actions}>
          <button onClick={addToCart} className={styles.add}>
            В корзину
          </button>
          <button onClick={openOrderPopup} className={styles.manager}>
            Заказ по телефону
          </button>
        </div>
        <div className={styles.bottom}>
          <Link to={ROUTES.HOME} className={styles.backButton}>
            Назад
          </Link>
        </div>
      </div>

      {isAddToCartPopupOpen && (
        <div className={styles.overlay} onClick={handleOverlayClick}>
          <div className={styles.popupContent}>
            <div className={styles.closeButton} onClick={closePopup}>
              ×
            </div>
            <p>Товар добавлен в корзину!</p>
          </div>
        </div>
      )}

      {isOrderPopupOpen && (
        <div className={styles.overlay} onClick={handleOverlayClick}>
          <div className={styles.popupContent}>
            <div className={styles.closeButton} onClick={closePopup}>
              ×
            </div>
            <p>Позвоните по одному из номеров телефона:</p>
            <p>
              <b>+7 900 001 87 83</b>
            </p>
            <p>
              <b>+7 900 001 87 82</b>
            </p>
            <p>или оставьте свой номер телефона ниже:</p>
            <InputMask
              className={styles.inputs}
              mask="+7 (999) 999-99-99"
              placeholder="Ваш номер телефона"
              name="phoneNumber"
              value={userData.phoneNumber}
              autoComplete="off"
              maskChar=""
              onChange={handleInputChange}
              required
            />
            <button onClick={handleProceedToPayment}>Отправить заявку</button>
          </div>
        </div>
      )}

      {showModal && (
        <div
          className={styles.overlay}
          onClick={closeModal}
        >
          <div
            className={styles.popupContent}
            onClick={(e) => e.stopPropagation()}
          >
            <div className={styles.closeButton} onClick={closeModal}>
              ×
            </div>
            <p>{modalMessage}</p>
          </div>
        </div>
      )}
    </motion.section>
  );
};

export default Product;