import React from "react";

import styles from "../../styles/Home.module.css";

const Banner = () => (
  <section className={styles.banner}>
    <iframe 
      title="Расположение Jasmine flower shop на карте" 
      src="https://yandex.ru/map-widget/v1/?um=constructor%3A33c98c31a62e319652da65ab0537fc9fa7bf7525927d8758611d60046c294f92&source=constructor" 
      width="1500" 
      height="570" 
      frameborder="0"
    ></iframe>
  </section>
);

export default Banner;