import React from "react";
import { Link, useNavigate } from "react-router-dom"; 
import { motion } from "framer-motion";
import { useInView } from "react-intersection-observer";
import styles from "../../styles/Products.module.css";
import { SH_BASE_URL } from "../../utils/constants";

import BannerTitle from "../BannerTitle/BannerTitle";

const generateSlug = (text) => {
  const transliterated = text
    .toLowerCase()
    .replace(/ /g, "-")
    .replace(/а/g, 'a')
    .replace(/б/g, 'b')
    .replace(/в/g, 'v')
    .replace(/г/g, 'g')
    .replace(/д/g, 'd')
    .replace(/е/g, 'e')
    .replace(/ё/g, 'yo')
    .replace(/ж/g, 'zh')
    .replace(/з/g, 'z')
    .replace(/и/g, 'i')
    .replace(/й/g, 'y')
    .replace(/к/g, 'k')
    .replace(/л/g, 'l')
    .replace(/м/g, 'm')
    .replace(/н/g, 'n')
    .replace(/о/g, 'o')
    .replace(/п/g, 'p')
    .replace(/р/g, 'r')
    .replace(/с/g, 's')
    .replace(/т/g, 't')
    .replace(/у/g, 'u')
    .replace(/ф/g, 'f')
    .replace(/х/g, 'kh')
    .replace(/ц/g, 'ts')
    .replace(/ч/g, 'ch')
    .replace(/ш/g, 'sh')
    .replace(/щ/g, 'sch')
    .replace(/ъ/g, '')
    .replace(/ы/g, 'y')
    .replace(/ь/g, '')
    .replace(/э/g, 'e')
    .replace(/ю/g, 'yu')
    .replace(/я/g, 'ya');

  return transliterated.replace(/[^a-z0-9-]/g, "");
};


const Products = ({ title, style = {}, products = [], amount }) => {
  const groupedProducts = products.reduce((acc, product) => {
    const { categoryId } = product;
    if (!acc[categoryId]) {
      acc[categoryId] = [];
    }
    acc[categoryId].push(product);
    return acc;
  }, {});

  const categoryIds = Object.keys(groupedProducts);

  return (
    <section className={styles.products} style={style}>
      {title && <h2>{title}</h2>}
      <div className={styles.price_block}></div>
      {categoryIds.map((categoryId) => {
        const categoryProducts = groupedProducts[categoryId];

        if (categoryId !== 'null') {
          return (
            <div key={categoryId} id={`category-${categoryId}`}>
              <AnimatedBannerTitle>
                {categoryProducts[0].category.name}
              </AnimatedBannerTitle>
              <ProductRow key={categoryId} row={categoryProducts.slice(0, amount)} />
            </div>
          );
        } 
      })}

      {groupedProducts.null && (
        <div id="category-null">
          <AnimatedBannerTitle>
            Смотрите также
          </AnimatedBannerTitle>
          <ProductRow row={groupedProducts.null.slice(0, amount)} />
        </div>
      )}
    </section>
  );
};

const AnimatedBannerTitle = ({ children }) => {
  const [ref, inView] = useInView({
    triggerOnce: true, 
    threshold: 0, 
  });

  const variants = {
    hidden: { opacity: 0, y: 50 }, 
    visible: { opacity: 1, y: 0, transition: { duration: 0.5, ease: "easeOut" } }, 
  };

  return (
    <motion.div
      ref={ref}
      initial="hidden"
      animate={inView ? "visible" : "hidden"}
      variants={variants}
      style={{ marginBottom: "20px" }}
    >
      <BannerTitle>{children}</BannerTitle> 
    </motion.div>
  );
};

const ProductRow = ({ row }) => {
  const [ref, inView] = useInView({
    triggerOnce: true,
    threshold: 0, 
  });
  const navigate = useNavigate();

  const containerVariants = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: {
        staggerChildren: 0.2,
      },
    },
  };

  const itemVariants = {
    hidden: { opacity: 0, y: 100 }, 
    visible: { opacity: 1, y: 0, transition: { duration: 0.6, ease: "easeOut" } }, 
  };

  return (
    <motion.div
      ref={ref}
      className={styles.list}
      initial="hidden"
      animate={inView ? "visible" : "hidden"}
      variants={containerVariants}
    >
      {row.map(({ id, image1, title, price }) => (
        <motion.div
          key={id}
          className={styles.product}
          variants={itemVariants}
          whileHover={{ scale: 1.05 }}
          style={{ marginBottom: inView ? "20px" : "0px" }}
        >
          <Link
            to={`/products/${generateSlug(title)}`}
            onClick={(e) => {
              e.preventDefault(); 
              navigate(`/products/${id}`);
            }}
          >
            <img
              className={styles.image}
              src={`${SH_BASE_URL}/${image1}`}
              alt={`${title} от Jasmine flower shop`}
            />
            <div className={styles.wrapper}>
              <h3 className={styles.title}>{title}</h3>
              <div className={styles.info}>
                <div className={styles.prices}>
                  <div className={styles.price}>{price} Руб.</div>
                </div>
              </div>
            </div>
          </Link>
        </motion.div>
      ))}
    </motion.div>
  );
};
export default Products;