import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import styles from "../../styles/Header.module.css";

import { ROUTES } from "../../utils/routes";

import LOGO from "../../images/logo.svg";
import AVATAR from "../../images/avatar.jpg";
import LOGOUT from "../../images/logout.svg";

import { toggleForm } from "../../features/user/userSlice";

const Header = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { currentUser, cart } = useSelector(({ user }) => user);

  const [values, setValues] = useState({ name: "Гость", avatar: AVATAR });

  useEffect(() => {
    if (!currentUser) return;

    setValues(currentUser);
  }, [currentUser]);

  const handleClick = () => {
    if (!currentUser) dispatch(toggleForm(true));
    else navigate(ROUTES.LOGOUT);
  };

  const handleLogout = () => {
    navigate(ROUTES.LOGOUT);
  };

  return (
    <div className={styles.header}>
      <h1 className={styles.logo}>
        <Link to={ROUTES.HOME}>
          <img src={LOGO} alt="Цветочный магазин Jasmine в Адлере" />
        </Link>
      </h1>

      <div className={styles.info}>
        <div className={styles.user} onClick={handleClick}>
          <div
            className={styles.avatar}
            style={{ backgroundImage: `url(${values.avatar})` }}
          />
          <div className={styles.username}>{values.name}</div>
          {currentUser && (
            <div className={styles.logout} onClick={handleLogout}>
              <img src={LOGOUT} alt="Logout" />
            </div>
          )}
        </div>

        {!currentUser || (currentUser && currentUser.role !== "ADMIN") ? (
          <address className={styles.phone}>
            <p>+7 900 001 87 83</p>
            <p>+7 900 001 87 82</p>
            <p>г. Сочи, ул. Гастелло, 43к1</p>
          </address>
        ) : null}

        <div className={styles.account}>
          <Link to={ROUTES.CART} className={styles.cart}>
            <svg className={styles.iconCart}>
              <use xlinkHref={`${process.env.PUBLIC_URL}/sprite.svg#bag`} />
            </svg>
            {!!cart.length && (
              <span className={styles.count}>{cart.length}</span>
            )}
          </Link>

          {currentUser && currentUser.role === "ADMIN" && (
            <>
              <Link to={ROUTES.PROFILE} className={styles.adminPanel}>
                Админ. панель
              </Link>
              <Link to={ROUTES.ORDERLIST} className={styles.orders}>
                Заказы
              </Link>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default Header;
