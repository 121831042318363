import React, { useEffect } from "react";
import Poster from "../Poster/Poster";
import Products from "../Products/Products";
import { useDispatch, useSelector } from "react-redux";
import WhatsappButton from "../Whatsapp/WhatsappButton";
import Categories from "../categories/Categories";
import Banner from "../Banner/Banner";
import About from "../Banner/About";
import Revievs from "../Banner/Revievs";
import Catalog from "../catalog/OurCatalog";
import { filterByPrice } from "../../features/products/productsSlice";

const Home = () => {
  const dispatch = useDispatch();
  const {
    products: { list, filtered },
    categories,
  } = useSelector((state) => state);

  useEffect(() => {
    window.scrollTo(0, 0);
    if (!list.lenght) return;

    dispatch(filterByPrice(100));
  }, [dispatch, list.lenght]);

  return (
    <>
      <Poster />
      <Categories products={categories.list} amount={5} />
      <Catalog />
      <Products products={list} amount={45} />
      <About />
      <Revievs />
      <Banner />
      <WhatsappButton />
    </>
  );
};

export default Home;
